/* Dark theme by default */

html {
    --accent-blue: #4093f4;
    --accent-blue-hover: #669fee;
    --accent-red: #ef5350;
    --accent-magenta: #ba68c8;
    --accent-green: #73b330;
    --accent-orange: #ed682e;
    --accent-purple: #ba68c8;
    --accent-usual: #7986cb;
    --accent-gray: #707070;
    --accent-yellow: #f59300;
    --accent-pink: #ec307f;

    --astro-table-border: #4c4c4c;
    --astro-table-border-light: #333333;

    --astro-table-object-major: #73b330;

    --aspects-table-border: #4b4b4b;
    --aspects-table-border-light: #626262;
    --aspects-table-background-hover: #444444;
    --aspects-table-background-current: #5d4d4d;

    --background: #1d1d1d;

    --bg-100: #151515;
    --bg-200: #1d1d1d;
    --bg-300: rgba(34, 34, 34, 1);
    --bg-400: #2a2a2a;
    --bg-410: #2a2a2a;
    --bg-500: #333333;
    --bg-600: #3b3b3b;
    --bg-700: #4c4c4c;
    --bg-sidebar: #2a2a2a;
    --bg-clear-modal: #1b1b1b;
    --bg-share: #151515;
    --bg-share-hover: rgba(255, 255, 255, 0.08);

    --bg-session: #2d2d2d;
    --bg-interval: #222222;
    --bg-break: repeating-linear-gradient(-45deg, #222222 0px, #222222 4px, #151515 4px, #151515 8px);

    --button-bg: rgba(255, 255, 255, 0.05);
    --button-bg-hover: rgba(255, 255, 255, 0.15);
    --button-border: rgba(255, 255, 255, 0.08);

    

    --colors-blue: #4093f4;
    --colors-red: #ef5350;
    --colors-purple: #ba68c8;
    --colors-green: #73b230;
    --colors-orange: #ed682e;
    --colors-white: white;
    --colors-yellow: #f59300;


    --dialog-background: #4c4c4c;

    --dropdown-background: #141414;
    --dropdown-color: #acacac;

    --element: rgba(255, 255, 255, 0.2);
    --element-light: rgba(255, 255, 255, 0.1);
    --element-neutral: rgba(255, 255, 255, 0.08);
    --element-neutral-hover: rgba(255, 255, 255, 0.12);
    --element-neutral-focus: rgba(255, 255, 255, 0.14);
    --element-disabled: rgba(255, 255, 255, 0.05);
    --element-neo: rgba(255, 255, 255, 0.05);
    --element-selected: linear-gradient(0deg, rgba(76, 154, 245, 0.12), rgba(76, 154, 245, 0.12)), rgba(255, 255, 255, 0.08);

    --bg-element-hover: rgba(255, 255, 255, 0.05);
    --bg-element-hover-hover: rgba(255, 255, 255, 0.08);

    --font-primary: #ffffff;
    --font-inverse: #424242;

    --icon-primary: rgba(255, 255, 255, 0.87);
    --icon-secondary: rgba(255, 255, 255, 0.6);
    --icon-third: rgba(255, 255, 255, 0.37);

    --input-background: rgba(0, 0, 0, 0.15);
    --input-border: rgba(255, 255, 255, 0.15);
    --input-border-hover: rgba(255, 255, 255, 0.24);
    --input-border-focus: rgba(255, 255, 255, 0.34);
    --input-background-active: #4c4c4c;

    --layer-color: rgba(0, 0, 0, 0.2);
    --layer-red-color: rgba(239, 83, 80, 0.08);
    --disabled-layer-color: rgba(29, 29, 29, 0.8);

    --aside-gradient: linear-gradient(0deg, rgba(45, 45, 45, 0.6), rgba(45, 45, 45, 0.6)), rgba(63, 63, 63, 0.95);

    --modal-bg: rgba(255, 255, 255, 0.08);

    --drag-preview-bg: rgba(0, 0, 0, 0.08);
    --drag-preview-border: rgba(255, 255, 255, 0.06);

    --notifications-background: #171717;

    --text-primary: rgba(255, 255, 255, 0.87);
    --text-secondary: rgba(255, 255, 255, 0.6);
    --text-third: rgba(255, 255, 255, 0.37);
    --text-inverse: #ffffff;
    --text-alert: rgba(255, 255, 255, 0.87);
    --text-white: rgba(255, 255, 255, 0.87);

    --warning-color: #ed682e;
    --warning-window-background: rgba(245, 147, 0, 0.05);
    --warning-window-border: rgba(245, 147, 0, 0.5);

    --workspace-background: #191919;
    --workspace-widget-background: #3b3b3b;

    --workspace-box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.4), 0px 0.6px 1.8px rgba(0, 0, 0, 0.2);

    --easeOutback: cubic-bezier(0.34, 1.56, 0.64, 1);
    --easeOutCirc: cubic-bezier(0, 0.55, 0.45, 1);
    --easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
    
}