@font-face {
    font-family: "Apercu Pro";
    src: url("../assets/fonts/ApercuPro-Regular.woff2") format("woff2"), url("../assets/fonts/ApercuPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Apercu Pro";
    src: url("../assets/fonts/ApercuPro-Medium.woff2") format("woff2"), url("../assets/fonts/ApercuPro-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

html {
    --font-size: 16px;

    font-size: var(--font-size);
    min-height: 100vh;
    color: var(--text-primary);

    height: 100%;
}

body {
    font-family: "Apercu Pro", sans-serif !important;
    margin: 0;
    min-height: 100vh;
    background-color: var(--workspace-background);
    overflow-x: hidden;
}

#root {
    display: flex;
    position: relative;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}

#root .widget {
    display: flex;
    width: 50vw;
}

#root .auth {
    display: block;
    width: 50vw;
    background-color: var(--background);
}

@media (max-width: 1024px) {
    #root .widget {
        display: none;
    }

    #root .auth {
        width: 100vw;
    }
}

#zmmtg-root,
#aria-notify-area {
    display: none;
}

* {
    scrollbar-width: thin;
    scrollbar-color: var(--dialog-background) transparent;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: black;
    border: 1px solid gray;
    border-radius: 2px;
}

button,
input {
    font-family: inherit;
    font-size: inherit;
}

ol {
    position: relative;
    counter-reset: section;
    list-style-type: none;
    padding-left: 0rem;
}

ol li {
    display: flex;
    align-items: baseline;
    counter-increment: section;
}

ol li a {
    color: var(--accent-blue);
    text-decoration: none;
}

ol li a:hover {
    text-decoration: underline;
}

li::before {
    font-family: 'Apercu Pro';
    font-weight: 500;
    color: var(--text-secondary);
}

@media (max-width: 1440px) {
    html {
        --font-size: 14px;
    }
}

@media (-webkit-max-device-pixel-ratio: 1.25) and (max-width: 1600px), (max-width: 1366px) {
    html {
        --font-size: 12px;
    }
}

@media (max-width: 1280px) {
    html {
        --font-size: 10px;
    }
}

@media (-webkit-max-device-pixel-ratio: 1.5) and (max-width: 1366px), (max-width: 1024px) {
    html {
        --font-size: 10px;
    }
}

@media (max-width: 768px) {
    html {
        --font-size: 10px;
    }
}

@media (max-width: 480px) {
    html {
        --font-size: 16px;
    }
}

/* @media screen and (max-width: 1024px) and (orientation: portrait) {
    #root {
        display: none;
    }
    body:before {
        content: "↑ Переверните устройство в панорамный режим ";
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 999;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100px;
    }
} */
